import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`The most conspicuous ruin remaining at the site of Gabii is a temple, generally attributed to Juno, which had six Ionic or Corinthian columns in the front and six on each side, excluding the back. The temple was composed of a single room (cella), and it was made of lapis Gabinus, a fire-resistant rock that was found in the quarries around Gabii and that also made its way into some of the buildings of Rome itself. The temple was situated in the middle of a podium, which had a colonnade of Doric columns along the back and extending around the sides. This colonnade stood in front of rooms of unknown function, perhaps multi-functional, for such uses as temple shops.`}{`[`}{`28] The temple was excavated and published by the Spanish School at Rome in the 1960s and 1970s.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/temple-of-juno-pic.jpg","alt":null,"title":"Aerial view of the Temple of Juno "}}></MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-with-background","title":"Temple of Juno","backgroundImage":"/assets/temple-of-juno-visconti-drawing.jpg","key":"temple-of-juno","parentKey":"previous-explorations"};

  